const navFunc = (() => {

  let nav = document.getElementById('nav');
  let navBtn = document.getElementById('navBtn');

  navBtn.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (navBtn.classList.contains('act') == true) {
      nav.classList.remove('act');
      navBtn.classList.remove('act');
    } else {
      nav.classList.add('act');
      navBtn.classList.add('act');
    }
  });


  let jsUlUl = nav.querySelectorAll('.js-ul-ul');
  let jsLinkIcon = nav.querySelectorAll('.js-link-icon');
  let hightList = []

  for (let i = 0; i < jsUlUl.length; i++) {
    let jsUlUlHeight = jsUlUl[i].clientHeight
    hightList.push(jsUlUlHeight);
    jsUlUl[i].style.height = 0
  }
  console.log(hightList);

  for (let i = 0; i < jsLinkIcon.length; i++) {
    jsLinkIcon[i].addEventListener('click', (event) => {
      console.log(i);
      if(jsUlUl[i].style.height == 0 || jsUlUl[i].style.height == '0px'){
        jsUlUl[i].style.height = `${hightList[i]}px`
        jsLinkIcon[i].classList.add('act')
      }else{
        jsUlUl[i].style.height = 0
        jsLinkIcon[i].classList.remove('act')
      }
    });
  }



})();

export default navFunc;