const navCategory = (() => {

  let jsNavCategory = (document.getElementById("jsNavCategory") != null);
  if (jsNavCategory) {

    let sW = document.documentElement.clientWidth;

    let jsNavCategory = document.getElementById('jsNavCategory');

    let jsStudygroupCategory = document.getElementById('jsStudygroupCategory');
    let jsCompanyCategory = document.getElementById('jsCompanyCategory');
    let jsServiceCategory = document.getElementById('jsServiceCategory');
    let jsPrivacyCategory = document.getElementById('jsPrivacyCategory');

    let categoryElem = [];

    if (sW > 750) {
      categoryElem = jsNavCategory.querySelectorAll('li');
    } else {
      if (document.getElementById('pageStudygroup')) {
        categoryElem = jsStudygroupCategory.querySelectorAll('.check');
      } else if (document.getElementById('pageCompany')) {
        categoryElem = jsCompanyCategory.querySelectorAll('.check');
      } else if (document.getElementById('pageService')) {
        categoryElem = jsServiceCategory.querySelectorAll('.check');
      }else if (document.getElementById('pagePrivacy')) {
        categoryElem = jsPrivacyCategory.querySelectorAll('.check');
      }
    }


    let categoryChange = document.querySelectorAll('.js-category-change');


    const actOn = (num) => {
      if (num == 0) {
      } else {
        categoryElem[num - 1].classList.remove("act")
      }
      categoryElem[num].classList.add("act")
    }

    const actOff = (num) => {
      categoryElem[num].classList.remove("act")
      if (num == 0) {
      } else {
        categoryElem[num - 1].classList.add("act")
      }
    }


    for (let i = 0; i < categoryElem.length; i++) {

      gsap.to(categoryElem[i], {
        scrollTrigger: {
          trigger: categoryChange[i],
          start: 'top center',
          onEnter: () => actOn(i),
          onLeaveBack: () => actOff(i),
          // markers: true,
        }
      });

    }



  }


})();
export default navCategory;